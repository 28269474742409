<template>
  <div class="relative">
    <spinner overlay="absolute" v-if="isLoading || !isReady"></spinner>
    <div v-show="isReady" :id="uniqueContainerId"></div>
  </div>
</template>

<script>
import { ref, reactive, computed, watchEffect } from "vue";
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import paymentMixin from "@/client/extensions/mixins/paymentComponent.js";
import addWindowScript from "@/client/extensions/composition/addWindowScript.js";
export default {
  mixins: [paymentMixin],
  // this is inherited, but aparently you cant mixin emits?
  emits: [
    "payment:created",
    "payment:canceled",
    "payment:complete",
    "payment:completed",
    "payment:failed",
  ],

  setup(props) {
    let { asyncOps, asyncOpsReady } = asyncOperations(props);
    let { addScript } = addWindowScript(props);
    return { asyncOps, asyncOpsReady, addScript };
  },
  data: function () {
    return {
      isReady: false,
      uniqueContainerId: "paypal-container-" + utilities.getUniqueNumber(),
    };
  },
  async mounted() {
    if (!(await this.loadPaypalScript())) {
      return;
    }
    this.isReady = true;
    await this.createPaypalButtons();
  },
  methods: {
    async getPaypalConfig() {
      let url = "payment/paypal/config";
      let payload = {};

      let confResult = await this.asyncOps.asyncCall(url, payload, {
        method: "get",
      });

      if (confResult.hasError) {
        console.log("get config error");
        return false;
      }

      return {
        clientId: confResult.data.clientId,
        // "AcPVdfFsGvQnewZfihN5X80v6_ZqRHVBum8N0EmNAJSR2PEFeA-izwHf1Y9wC_Z3Ux7hBp0xrzc-26y5",
      };
    },
    async loadPaypalScript() {
      if (utilities.isSSR()) {
        return false;
      }
      let paypalConfig = await this.getPaypalConfig();
      if (!paypalConfig) {
        this.emitFailedPayment({
          context: {
            mesasge: "Failed to fetch config",
          },
        });
        return false;
      }

      this.setIsLoading(true);
      let clientId = paypalConfig.clientId;

      await this.addScript(
        `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${this.currency}`
      );

      this.setIsLoading(false);
      return true;
    },
    async createPaypalButtons() {
      if (utilities.isSSR()) {
        return false;
      }

      window.paypal
        .Buttons({
          // Order is created on the server and the order id is returned
          createOrder: async () => {
            // create payment
            let url = "payment/";
            let user = this.sg("user.profile");
            let payload = {
              UserId: user.id,
              amount: this.amount,
              currency: this.currency,
              note: "test paypal",
              handler: "paypal",
            };

            let createResult = await this.asyncOps.asyncCall(url, payload, {
              method: "post",
            });
            if (createResult.hasError) {
              throw new Error("create payment error");
            }
            this.payment = createResult.data.item;

            // initialize payment with paypal
            let initUrl = `payment/${this.payment.uuid}/paypal/createOrder`;

            const result = await this.asyncOps.asyncCall(
              initUrl,
              {},
              { method: "patch" }
            );

            if (result.hasError) {
              throw new Error("init payment error");
            }

            return result.data.paypal.orderId;
          },
          onApprove: async (data) => {
            console.log("paypal capture runs");
            let captureUrl = `payment/${this.payment.uuid}/paypal/CaptureOrder`;

            const result = await this.asyncOps.asyncCall(
              captureUrl,
              {},
              { method: "patch" }
            );

            if (result.hasError) {
              this.failAfterPaymentComplete();
              return false;
            }

            console.log("capture result", result);
            this.emitSuccessfulPayment();
          },
        })
        .render(`#${this.uniqueContainerId}`);

      return true;
    },
    async executePayment(amount, currency) {
      if (!amount) {
        amount = this.amount;
      }

      if (!currency) {
        currency = this.currency;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
